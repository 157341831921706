<script setup>
const internal = useInternal()
const info = useCookie('info')

defineProps({
  content: {
    type: String,
    default: ''
  }
})

const clear = () => {
  info.value = false
  internal.clearInfo()
}
</script>

<template>
  <ClientOnly>
    <div class="info">
      <div class="info_wrapper">
        <vContent :content="content" />
        <div class="close">
          <vIcon
            id="close"
            @click="clear"
          />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<style scoped>
:deep(.content) {
  max-width: 900px;
}
.info {
  padding: 16px 24px 0;
  background-color: rgb(var(--color-rgb)/ .06)
}
.info_wrapper {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 24px;
  column-gap: 24px;
}
.close {
  cursor: pointer;
  color: rgb(var(--color-rgb) / .5)
}
@media (max-width: 512px) {
  :deep(.content) {
    font-size: 13px;
  }
}
</style>
