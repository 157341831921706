<script setup>
import vObavestenje from '@/components/vObavestenje.vue'

const internal = useInternal()
const obavestenje = shallowRef(null)
const info = useCookie('info')

watch(internal, (x) => {
  console.log('info ', x.info)
  if (x.info && !info.value) obavestenje.value = vObavestenje
  else obavestenje.value = null
})
</script>

<template>
  <NuxtLoadingIndicator
    color="var(--brand-color)"
    :height="4"
  />
  <vNav />
  <Component
    :is="obavestenje"
    v-if="obavestenje"
    :content="internal.info"
  />
  <main>
    <slot />
  </main>
  <vFooter />
  <vPostFooter />
  <vCart />
  <vToasts />
  <vSvgSprite />
</template>

<style scoped>
/* #__nuxt {
  display: grid;
  grid-template-rows: 48px 56px  auto auto auto;
  min-height: 100%;
  transition: transform 256ms ease;
  -webkit-tap-highlight-color: transparent;
} */
#__nuxt {
  display: grid;
  grid-template-rows: 84px  auto auto auto;
  min-height: 100%;
  transition: transform 256ms ease;

}
</style>
